import { CLUSTER, PERMUTIVE_API_KEY_BF_US, PERMUTIVE_PROJECT_ID_BF_US } from '../constants';

const PERMUTIVE_TRACKER_NAME = 'permutive';
const permutiveFns = [
  'addon',
  'identify',
  'track',
  'trigger',
  'query',
  'segment',
  'segments',
  'ready',
  'on',
  'once',
  'user',
  'consent',
];

// Load the actual permutive script and initialize it

export function loadPermutiveScript() {
  const snippet = `(function (n, e, o, r, i) {
    if (!e) {
      (e = e || {}),
        (window['${PERMUTIVE_TRACKER_NAME}'] = e),
        (e.q = []),
        (e.config = i || {}),
        (e.config.projectId = o),
        (e.config.apiKey = r),
        (e.config.environment = '${CLUSTER}');
      for (var t = ${JSON.stringify([...permutiveFns])}, c = 0; c < t.length; c++) {
        var f = t[c];
        e[f] = (function (n) {
          return function () {
            var o = Array.prototype.slice.call(arguments, 0);
            e.q.push({
              functionName: n,
              arguments: o,
            });
          };
        })(f);
      }
    }
  })(
    document, window['${PERMUTIVE_TRACKER_NAME}'], '${PERMUTIVE_PROJECT_ID_BF_US}', '${PERMUTIVE_API_KEY_BF_US}', {}
  );`
  return snippet;
}

