/* eslint-disable camelcase */
import styles from './link.module.scss';
import { useTrackingContext } from '../../../../hooks/tracking/useTrackingContext';

const Link = ({
  link,
  name,
  target = '_blank',
  linkTrackingInfo = {},
  onSignInClick,
}) => {
  const { trackInternalLink } = useTrackingContext();
  const isSignInButton = typeof onSignInClick === 'function';

  const onLinkClick = () => {
    if (isSignInButton) {
      onSignInClick(linkTrackingInfo);
      return;
    }

    if (Object.keys(linkTrackingInfo).length !== 0) {
      trackInternalLink({
        context_page_type: 'feed',
        unit_name: 'main',
        ...linkTrackingInfo,
      });
    }
  };

  return (
    <a
      className={styles.link}
      onClick={onLinkClick}
      {...!isSignInButton && {
        target,
        href: link,
        rel: 'noreferrer'
      }}
    >
      {name}
    </a>
  );
};

export default Link;
