export const FacebookIcon = ({ bgColor = '#FFF', fillColor = '#1877F2' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={bgColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Facebook</title>
    <g clipPath="url(#clip0_472_1809)">
      <path
        d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9705 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.74906V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9897 24 12Z"
        fill={bgColor}
      />
      <path
        d="M16.6711 15.4688L17.2031 12H13.875V9.74906C13.875 8.80008 14.34 7.875 15.8306 7.875H17.3438V4.92188C17.3438 4.92188 15.9705 4.6875 14.6576 4.6875C11.9166 4.6875 10.125 6.34875 10.125 9.35625V12H7.07812V15.4688H10.125V23.8542C10.736 23.9501 11.362 24 12 24C12.638 24 13.264 23.9501 13.875 23.8542V15.4688H16.6711Z"
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_472_1809">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
